import React from 'react'
import { Modal } from 'antd'

const CoverLetterModal = ({ modalOpen, openCoverLetter, record }) => {
  const closeModalHandler = () => {
    openCoverLetter(false)
  }
  return (
    <>
      {modalOpen ? (
        <Modal
          width={800}
          footer={null}
          onCancel={closeModalHandler}
          visible={modalOpen}
          okText="Save"
          className="p-0 candidateAlertModal shadow-lg"
        >
          <div className="modal-head bg-voyage py-5 px-5 rounded-tl rounded-tr flex items-center justify-between">
            <p className="font-sans font-bold text-base text-white mb-0">
              Cover Letter
            </p>
            <img
              onClick={() => closeModalHandler()}
              className="w-4 cursor-pointer mb-0"
              src="/icons/icon-modal-close.svg"
              alt="Close modal icon"
            />
          </div>
          <div
            className="bg-white px-6 py-4"
            dangerouslySetInnerHTML={{
              __html: record?.cover_letter_text,
            }}
          ></div>
        </Modal>
      ) : (
        <></>
      )}
    </>
  )
}
export default CoverLetterModal
