import React from 'react'
import PageNav from '../../components/pageComponents/common/pageNav'
import Wrapper from '../../components/wrapper'
import ApplicationHistoryComponent from '../../components/pageComponents/memberDashboard/application-history'

const MemebrDashboardApplicationHistory = ({ location }) => {
  const Children = props => {
    return (
      <>
        <PageNav navItem="Candidate  Application History" />
        <ApplicationHistoryComponent {...props} />
      </>
    )
  }
  return (
    <Wrapper privateRoute location={location} title="Dashboard | MedReps.com">
      <Children />
    </Wrapper>
  )
}
export default MemebrDashboardApplicationHistory
